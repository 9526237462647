body {
  margin: 0;
  font-family: 'Archivo' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#011422;
  overflow-x: hidden;
}
/* *{
  font-family: 'Archivo' !important;
  box-sizing: border-box;
} */

@font-face {
    font-family: 'Inter';
    src: url('assets/font/Inter-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('assets/font/Inter-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('assets/font/Inter-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('assets/font/Inter-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('assets/font/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('assets/font/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('assets/font/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('assets/font/Inter-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('assets/font/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
/* Archivo fonts */
@font-face {
  font-family: 'Archivo';
  src: url('assets/font/Archivo-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Archivo';
  src: url('assets/font/Archivo-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Archivo';
  src: url('assets/font/Archivo-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Archivo';
  src: url('assets/font/Archivo-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Archivo';
  src: url('assets/font/Archivo-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Archivo';
  src: url('assets/font/Archivo-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Archivo';
  src: url('assets/font/Archivo-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/font/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('assets/font/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('assets/font/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/*
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/
.infoTooltip:hover + .infotooltipbox , .infotooltipbox:hover {
    display: block;
}
.infotooltipbox{
    display: none;
}
input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button{
  -webkit-appearance:none;
}
::-webkit-scrollbar {
    width: 2px;
}
::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.32); 
    border-radius: 4px;
}
@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #041827; 
  border-radius: 2px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #2c485c;
  /* outline: 1px solid slategrey; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #444444; 
}
.MuiPaper-rounded{
  border-radius: 10px ;
}
.MuiPaper-root, 
.MuiPopover-paper,
.MuiMenu-list{
  background:rgb(3 29 49);
  color:#fff;   
}
.MuiCheckbox-colorSecondary.Mui-checked{color:transparent !important;}
.apexcharts-xaxis-label{color:#CACACA !important;}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none;
}
.MuiInput-underline:before,.MuiInput-underline:after{display: none;}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{border:none;}
.apexpiechart .apexcharts-canvas{ margin: 0 auto; }
.MuiMenu-paper{
  top: 80px !important;
  background-color:rgb(3 29 49) !important;
}